<template>
  <div class="time-selector">
    <el-radio-group
      v-model="radioValue"
      :disabled="disabled"
      size="mini"
      @change="change"
    >
      <el-radio-button
        v-for="(item, index) in newList"
        :key="index"
        :label="item.value"
      >
        {{ item.name }}
      </el-radio-button>
    </el-radio-group>
    <gl-date-picker
      v-if="!pickerDisabled"
      @change="dateChange"
      :ifInitRest="true"
      v-model="customDate.dateTime"
      :startTime.sync="customDate.startTime"
      :endTime.sync="customDate.endTime"
      valueFormat="yyyy-MM-dd"
      :disabled="pickerDisabled"
    >
    </gl-date-picker>
  </div>
</template>

<script>
export default {
  name: 'TimeSelector',
  props: {
    value: {
      type: String,
      default: 'week',
    },
    size: {
      type: String,
      default: 'mini', //small  medium
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      radioValue: '1',
      list: [
        { name: '七天', value: '1' },
        { name: '一个月', value: '2' },
        { name: '年', value: '3' },
        // {name: '自定义', value: 'custom'},
      ],
      customDate: {},
      pickerDisabled: true,
    }
  },
  computed: {
    newList() {
      if (this.options.length) {
        return this.options
      }
      return this.list
    },
  },
  watch: {
    radioValue: {
      handler: function (newVal) {
        this.pickerDisabled = newVal == 'custom' ? false : true
      },
    },
  },

  methods: {
    change(e) {
      let params = {
        type: e,
      }
      this.$emit('input', e)
      this.$emit('change', e, params)
    },
    dateChange(e) {
      let params = {
        type: this.radioValue,
        ...this.customDate,
      }
      this.$emit('change', this.radioValue, params)
    },
  },
}
</script>

<style lang="scss" scoped>
.time-selector {
  display: flex;
  justify-content: flex-start;
  width: 480px;
  .el-input__inner {
    width: 100px !important;
  }

  .gl-date-picker {
    width: 210px;
  }
}
</style>
