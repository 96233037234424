<template>
  <div class="merchant-home">
    <gl-card>
      <chart-top 
        title="销量金额" 
        @click-change="onTimeChange('sum', $event)"
        :type.sync="sumChartType">
      </chart-top>
      <homeChart :chartData="sumData" :type="sumChartType"/>
    </gl-card>
    <gl-card>
      <chart-top 
        title="销量数量" 
        @click-change="onTimeChange('num', $event)"
        :type.sync="numChartType">
      </chart-top>
      <homeChart :chartData="numData" :type="numChartType"/>
    </gl-card>
  </div>
</template>

<script>
import chartTop from '../../../components/chart-top.vue'
import homeChart from '../../../components/home-chart.vue'
export default {
  components: {chartTop,homeChart},
  data(){
    return{
      sumData: {},  //销量金额数据
      sumChartType: 'line',
      numChartType: 'bar',
      numData: {}, //销量数量数据
      funAndForm: {
        'sum': {
          form: {
            days: '1'
          },
          func: this.loadSumData
        },
        'num': {
          form: {
            days: '1'
          },
          func: this.loadNumData
        },
      },
      
      resData: {
        dateTimes: ['2022-05','2022-06','2022-07','2022-08','2022-09','2022-10','2022-11'],
        tradeNums: [12,43,35,4,1,16,88],
        series: [ // 两条线路
          {
            name: '2022-7',
            type: 'line',
            stack: 'Total',
            data: [320, 332, 301, 334, 390, 330, 320]
          },
          {
            name: '2022-8',  
            type: 'line',
            stack: 'Total',
            data: [820, 932, 901, 934, 1290, 1330, 1320]
          }
        ]
      },
    }
  },
  mounted(){
    // this.loadSumData()
    // this.loadNumData()
  },
  methods: {
    async loadSumData(){
      const params = this.funAndForm['sum'].form

      const res = this.resData
      this.sumData = {
        name: '销量金额',
        type: 'line',
        xData: [...res.dateTimes] || [],
        yData: [...res.tradeNums] || [],
        series: []
      }
    },
    loadNumData(){
      const params = this.funAndForm['num'].form

      const res = this.resData
      this.numData = {
        name: '销量数量',
        type: this.numChartType,
        xData: res.dateTimes,
        yData: res.tradeNums,
        // series: res.series,  // 两条线路
      }  
    },
    onTimeChange(chartType, item){

      return

      const {type, startTime, endTime, dateTime} = item
      const loadList = this.funAndForm[chartType].func
      
      this.funAndForm[chartType].form.days = type
    
      if(type == 'custom'){
        if(!startTime && !endTime) return
        console.error('自定义选项时间:', dateTime)
        this.funAndForm[chartType].form.startTime = startTime
        this.funAndForm[chartType].form.endTime = endTime
      }else{
        this.funAndForm[chartType].form['startTime'] && delete this.funAndForm[chartType].form['startTime']
        this.funAndForm[chartType].form['endTime'] && delete this.funAndForm[chartType].form['endTime']
      } 
      
      loadList && loadList()
    },
  },
}
</script>

<style lang="scss" scoped>

</style>