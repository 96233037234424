<template>
  <div class="top-handle">
    <div class="left">
      <i class="iconfont icon-bar-line" style="color:#409EFF;margin-right:3px;font-size: 18px;"></i>
      {{title}}
    </div>
    <div class="conter">
      <time-selector @change="change" :options="options"></time-selector>
    </div>
    <div class="right">
      <i @click="iconChange('line')" :class="type == 'line' ? 'active' : ''" class="iconfont icon-line" style="font-size:20px;"></i>
      <i @click="iconChange('bar')" :class="type == 'bar' ? 'active' : ''" class="iconfont icon-bar" style="font-size:20px;"></i>
    </div>
  </div>
</template>

<script>
import TimeSelector from './time-selector.vue'
export default {
  name: 'ChartTop',
  components: {TimeSelector},
  props: {
    title: {
      type: String,
      default: '图表'
    },
    type: {
      type: String,
      default: 'line'
    },
    options: {
      type: Array,
      default: () => []
    },
  },
  data(){
    return{}
  },
  methods: {
    change(type,item){
      this.$emit('change', type, item)
      this.$emit('click-change', item)
    },
    iconChange(type){
      this.$emit('update:type', type)
      this.$emit('iconChange', type)
    }
  }
}
</script>

<style lang="scss" scoped>
.top-handle{
  display: flex;
  justify-content: space-between;
  .left {
    display: flex;
    align-items: center;
    width: 20%;
    margin-left: 3px;
    font-size: 18px;
    font-weight: bold;
    font-weight: 550;
    // color: #606266;
    // color: #333;
    // color: #303133;
    color: #404246;
  }
  .center{
    width: 65%;
  }
  .right{
    width: 15%;
    font-size: 18px;
    display: flex;
    align-items: center;
    i{
      cursor: pointer;
    }
    i+i{
      margin-left: 10px;
    }
  }
  .active{
    color: #409EFF;
  } 
}
</style>