<template>
  <div ref="lineChart" id="lineChart" :style="{width: '100%', height: height}"> 我是echarts </div>
</template>

<script>
import * as echarts from 'echarts'
import chartMixin from '../mixins/chart.mixin'

export default {
  name: 'lineChart',
  mixins: [chartMixin],
  props: {
    chartData: {
      type: Object,
      default: () => {}
    },
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      default: '300px'
    },
    type: {
      type: String,
      default: 'line', // line:折线 bar:柱状 pie:饼状
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: ''
    }
  },
  data(){
    return{
      myChart: null,
      initOption: {},
    }
  },
  watch: {
    chartData: {
      handler: function(newVal){
        newVal && this.initChart()
      },
      deep: true
    },
    type: {
      handler: function(newVal){
        this.changeType(newVal)
      }
    }
  },
  methods: {
    initChart(){
      if(this.myChart != null){
        this.myChart.dispose() //解决echarts dom已经加载的报错
      }
      this.myChart = echarts.init(this.$refs.lineChart)
      this.initOption = {
        title: {
          // text: 'evan-echarts',
        },
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        legend: {
          // data: ['evan', '2022-7'],  // , '2022-8', '2022-9', '2022-10'
          // data: [this.chartData.name]   ,  // [this.chartData.name]
          data: (this.chartData.series && this.chartData.series.length > 0) ? this.chartData.series.map(item => item.name) : [this.chartData.name]
        },
        xAxis: {
          type: 'category',
          booundaryGap: false,
          // data: ['2022-6', '2022-7', '2022-8', '2022-9', '2022-10'],  // , '2022-8', '2022-9', '2022-10'
          data: this.chartData.xData,
        },
        yAxis: {
          type: 'value',
          // name: 'yaxis-name',
          nameGap: 20,
        },
        series: (this.chartData.series && this.chartData.series.length > 0) ? this.chartData.series : [
          {
            name: this.chartData.name ||'name',
            type: this.chartData.type ||'line',
            stack: 'Total',
            data: this.chartData.yData,
          },
          // {
          //   name: '2022-7',
          //   type: 'line',
          //   stack: 'Total',
          //   data: [820, 932, 901, 934, 1290, 1330, 1320]
          // }
        ]
      }
      this.myChart.setOption(this.initOption)
    },
    changeType(type){
      if(this.chartData.series && this.chartData.series.length > 0){
        this.initOption.series = this.chartData.series.map(item => {
          return {
            ...item,
            type: type,
          }
        })
      }else{
        this.initOption.series[0].type = type
      }
      // if(this.myChart == null){
      //   return this.initChart()
      // }
      this.myChart.setOption(this.initOption)
    }
  },
  beforeDestroy() {
    if (!this.myChart) {
      return
    }
    this.myChart.dispose() // 销毁
    this.myChart = null
  },
}
</script>

<style>

</style>